import React, { Suspense } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import LoadingFallback from "./components/LoadingFallback";
import { LS_KEY_AGENT } from "./constant";

const Landing = React.lazy(() => import("./components/Landing"));
const DaftarPenyertaan = React.lazy(() => import("./components/DaftarPenyertaan"));
const DaftarDisimpan = React.lazy(() => import("./components/DaftarDisimpan"));
const SumbanganProjek = React.lazy(() => import("./components/SumbanganProjek"));
const SedekahKorban = React.lazy(() => import("./components/SedekahKorban"));
const Pembayaran = React.lazy(() => import("./components/Pembayaran"));
const Selesai = React.lazy(() => import("./components/Selesai"));
const ButiranPembayaran = React.lazy(() => import("./components/ButiranPembayaran"));
const Administrator = React.lazy(() => import("./components/Administrator"));

export default function App() {
  return (
    <Router>
      <Suspense fallback={<LoadingFallback />}>
        <Route path="/" exact component={Landing} />
        <Route path="/daftar-penyertaan" exact component={DaftarPenyertaan} />
        <Route path="/daftar-berjaya" exact component={DaftarDisimpan} />
        <Route path="/sumbangan-projek" exact component={SumbanganProjek} />
        <Route path="/sedekah-korban" exact component={SedekahKorban} />
        <Route path="/pembayaran" exact component={Pembayaran} />
        <Route path="/selesai" exact component={Selesai} />
        <Route path="/butiran-pembayaran" exact component={ButiranPembayaran} />
        <Route path="/administrator" component={Administrator} />

        <Route
          path="/:agent"
          render={(props) => {
            const reservePath = [
              "daftar-penyertaan",
              "daftar-berjaya",
              "sumbangan-projek",
              "pembayaran",
              "selesai",
              "butiran-pembayaran",
              "administrator",
              "sedekah-korban",
            ];

            const path = props.match.params.agent;

            // jika dalam reservePath, maka ia bukan agent
            // jika tiada dalam reservePath, maka ia dianggap agent

            if (reservePath.includes(path)) {
              return null;
            } else {
              localStorage.setItem(LS_KEY_AGENT, path);
              props.history.replace("/");
            }
          }}
        />
      </Suspense>
    </Router>
  );
}
