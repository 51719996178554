import React from "react";
import { Icon } from "antd";

export default function LoadingFallback() {
  return (
    <div
      style={{ position: "fixed", backgroundColor: "#fff", top: 0, right: 0, left: 0, bottom: 0 }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          height: 55,
          backgroundColor: "#c52525",
          lineHeight: "55px",
          paddingLeft: 20,
          color: "#fff"
        }}
      >
        <span>Loading...</span>
      </div>

      <div style={{ paddingTop: 150, textAlign: "center" }}>
        <Icon type="loading" style={{ color: "#c52525", fontSize: 64 }} />
      </div>
    </div>
  );
}
